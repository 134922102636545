<template>
    <div>
        <!-- <OBJECT id="WebBrowser1" height=0 width=0 classid=CLSID:8856F961-340A-11D0-A96B-00C04FD705A2 name=wb></OBJECT> -->
        <!-- <div id="printMe" style="background:red;">
            <p>葫芦娃，葫芦娃</p>
            <p>一根藤上七朵花 </p>
            <p>小小树藤是我家 啦啦啦啦 </p>
            <p> 啦啦啦啦</p>
            <p>...</p>

            <div class="describle">
                <van-field v-model="value" label="文本" placeholder="请输入用户名" />
            </div>
        </div> -->
    
        <!-- <button v-print="printObj">打印</button> -->
        <!-- <vue-easy-print ref="easyPrint" tableShow> -->
            <div id="div_print" style="width: 965px; margin: auto;">
                        打印内容！！！    
            </div>
        <!-- </vue-easy-print> -->
        <!-- <input type="button" class="button" id="print" value="打印"  v-on:click="printFn"> -->
        <button class="printIe" id='print' @click="printMeAuto">打印</button>
    </div>
</template>

<script>
import { Field } from 'vant';
import vueEasyPrint from "vue-easy-print";

import { getLodop } from '@/utils/lodopFuncs.js'
export default {
    data() {
        return {
            value:'',
            // printObj: {
            //     id: 'printMe',
            //     popTitle: '打印标题',
            // }
        }
    },
    methods: {
        printFn() {
        //     console.info(this.$refs.easyPrint)
        //   this.$refs.easyPrint.print()

            var WebBrowser = '<OBJECT NAME="WebBrowserObject" WIDTH=0 HEIGHT=0 CLASSID="CLSID:8856F961-340A-11D0-A96B-00C04FD705A2"></OBJECT>'; 
            document.body.insertAdjacentHTML('beforeEnd', WebBrowser); 
            WebBrowserObject.ExecWB(6, 6); 
            WebBrowserObject.outerHTML = ""; 
        //  wb.ExecWB(6,6)
        },

        printMeAuto() {   //这个是打印代码
            var self = this
            let LODOP = getLodop() //主要代码
            LODOP.PRINT_INIT()
            LODOP.SET_PRINT_PAGESIZE(3, 580, 200)
            LODOP.ADD_PRINT_HTM(
                0,
                0,
                '100%',
                '100%',
                document.getElementById('div_print').innerHTML  //获取要打印部分html
            )
            //以上为声明打印参数，具体请参照官网示例
            //以下是成功加入打印队列之后的回调，如果不用刻意不看，直接执行LODOP.PRINT()
            LODOP.SET_PRINT_MODE('CATCH_PRINT_STATUS', true)

            if (LODOP.CVERSION) {
                //判断c_lodop是否存在，安装了c-lodop就会存在
                LODOP.On_Return = function(TaskID, Value) {
                // console.log('TaskID:' + TaskID)
                console.log('Value:' + Value) //job代码
                self.jobCode = Value
                if (!!Value) {
                    //如果成功，改变打印次数
                    ChangePrintCount(self.newDataAuto).then((res) => {
                    if (res.status !== 1) {
                        self.$notify.error({
                        title: '错误',
                        message: res.message,
                        })
                    }
                    })
                    return
                }
                }
                LODOP.PRINT() //这是执行打印（无预览）
                // LODOP.PREVIEW()   这是有预览打印
                self.dialogVisible = false
                return
            } else {
                console.log('c-lodop出错了')
            }
        },
    },
    mounted() {
        
    },
    components: {
        [Field.name]: Field,
        vueEasyPrint
    },
}
</script>
